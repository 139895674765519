.appContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.scanPage {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.resultPage {
    /* position: absolute; */
    /* background-color: white; */
    /* height: 100%;
    width: 100%; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.cameraContainer {
    width: 80%;
}

.qrCode {
    width: 100%;
}

.mockGetButton {
    position: absolute;
    right: 100px;
    bottom: 100px;
    float: right;
}